import { PageHeader } from "antd";
import React from "react";

// displays a page header

export default function Header() {
  return (
    <a href="https://eips.ethereum.org/EIPS/eip-3074" target="_blank" rel="noopener noreferrer">
      <PageHeader
        title="🔥 Wasted on Approvals"
        subTitle="How much ETH have you lost to token approvals?"
        style={{ cursor: "pointer" }}
      />
    </a>
  );
}
