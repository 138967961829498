import React, {useState} from "react";
import {Button} from "antd";
import axios from "axios";
import { ethers } from "ethers";
import { useEffect } from "react";
import TweetEmbed from 'react-tweet-embed'

/**
 * web3 props can be passed from '../App.jsx' into your local view component for use
 * @param {*} yourLocalBalance balance on current network
 * @param {*} readContracts contracts from current chain already pre-loaded using ethers contract module. More here https://docs.ethers.io/v5/api/contract/contract/
 * @returns react component
 */
 function Home({ provider, etherscanApiKey, address, price }) {
  const [ethPaid, setEthPaid] = useState(0);
  const [numApprovals, setNumApprovals] = useState(0);
  const [currentAddress, setCurrentAddress] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (provider !== null && (currentAddress === "" || currentAddress !== address) && address &&  address.length === 42) {
      setCurrentAddress(address);
      setLoading(true);
      (async function () {
        const txs = await getTxs(provider);
        if (Array.isArray(txs)) {
          const {txApprovals,logApprovals} = await clean(provider, txs);
          const ethUsed = await calculateGas(txApprovals, logApprovals);
          const totalApprovals = txApprovals.length + logApprovals.reduce((acc,value) => acc + value.numApprovals, 0);
          setEthPaid(ethUsed);
          setNumApprovals(totalApprovals)
          setLoading(false);
        } else {
          console.log("Possible error: ", txs)
        }
      })();
    }
  });

  const getTxs = async (provider) => {
    // Note max offset size = 9999
    const call = `https://api.etherscan.io/api?module=account&action=txlist&address=${address}&startblock=0&endblock=99999999&page=1&offset=9999&sort=asc&apikey=${etherscanApiKey}`;
    const txs = await axios.get(call);
    return txs.data.result;
  }

  const clean = async (provider, txs) => {
    const filtered = txs.filter(x => { return x.input != "0x"});
    const txApprovals = [];
    const logApprovals = [];
    for await (const tx of filtered) {
      // If input data is an approval, then EOA made approval
      // Take function signature from input data
      // First 4 bytes of keccak "Approve(address,uint256)
      if (tx.input.substr(0,10) == "0x095ea7b3") {
        txApprovals.push(tx);
      }

      // Skip tx was a standard approval
      if (!txApprovals.includes(tx)) {
        // Check for approval within smart contracts, not directly EOA
        // Probably a sc wallet, or proxy
        const receipt = await provider.getTransactionReceipt(tx.hash);
        receipt.logs.forEach(log => {
          log.topics.forEach(topic => {
            // keccak "Approval(address,address,uint256)" == 0x8c5be1e5ebec7d5bd14f71427d1e84f3dd0314c0f7b2291e5b200ac8c7c3b925
            let containsApproval = false;
            let numApprovals = 0;
            if (topic == "0x8c5be1e5ebec7d5bd14f71427d1e84f3dd0314c0f7b2291e5b200ac8c7c3b925") {
              numApprovals++;
              containsApproval = true;
            }
            if (containsApproval) logApprovals.push({tx, numApprovals});
          })
        })
      }
    };
    return {
      txApprovals,
      logApprovals
    };
  };

  const calculateGas = (txs, logApprovals) => {
    let ethPaid = 0;
    // calculate the tx approvals
    txs.forEach(tx => {
      ethPaid += (tx.gasUsed * ethers.utils.formatUnits(tx.gasPrice));
    });
    // calculate non-tx approvals
    // here we estimate that an approval roughly costs 23k gas, its a bit aggresive
    logApprovals.forEach(({tx, numApprovals}) => {
      ethPaid += ((numApprovals*23000) * ethers.utils.formatUnits(tx.gasPrice));
    });
    return ethPaid;
  }

  return (
    <div>
      <div style={{ fontSize: "2em", marginTop: "2em" }}>
      <br/>
        <span style={{ marginRight: 8 }}>📈</span>
        You made a total of {" "}
        <span style={{ fontWeight: "bold", color: "black" }}>{numApprovals} approvals</span> {" "}
        <br />
        <span style={{ marginRight: 8 }}>⛽️</span>
        That's {" "}
        <span style={{ fontWeight: "bold", color: "black" }}>wasted</span> {" "}
        <span style={{ fontWeight: "bold", color: "red" }}>~{ethPaid} ETH</span> {" "}
        <span style={{ fontWeight: "bold", color: "red" }}>({"$" + (parseFloat(ethPaid) * price).toFixed(2)} USD)</span>
      </div>
      
      {/* Loader */}
      <span style={{ fontSize: "1em", color: "lightgray" }}>{loading ? "Loading results..." : null}</span>
      
      <div style={{ fontSize: "2em", marginTop: "2em" }}>
        <Button
            onClick={() => {
              window.open("https://eips.ethereum.org/EIPS/eip-3074");
            }}
            size="large"
            shape="round"
          >
            <span style={{ marginRight: 8 }} role="img" aria-label="support">
              💡
            </span>
            EIP-3074
        </Button>
        <span style={{marginLeft: 10}}>solves this!</span>
      </div>

      <div style={{ fontSize: "2em", marginTop: "2em" }}>
        <span>Voice your opinion & join the discussion</span> {" "}
        <Button
            onClick={() => {
              window.open("https://ethereum-magicians.org/t/eip-3074-auth-and-authcall-opcodes/4880");
            }}
            size="large"
            shape="round"
          >
            <span style={{ marginRight: 8 }} role="img" aria-label="support">
              💬
            </span>
            EIP-3074
        </Button>
      </div>
      <div style={{fontSize: "2em", margin: "2em 25% 0em 25%" }}>
        <span>Check out what people are saying!</span>
        <div style={{display: "flex", justifyContent: "space-around"}}>
          <TweetEmbed
           id="1482029508001632257"
           placeholder={"Loading tweet..."}
           options={{conversation: "none", width: "550"}}
          />
          <TweetEmbed 
            id="1482052414031089666" 
            placeholder={"Loading tweet..."}
            options={{conversation: "none", width: "550"}}
          />
        </div>
      </div>
      <div style={{marginBottom: "5em"}}>
        <Button
          onClick={() => {
            window.open("https://github.com/GregTheGreek");
          }}
          size="large"
          shape="round"
        >
          Made with {" "}
          <span style={{ marginRight: 8 }} role="img" aria-label="support">❤️</span>
           by GregTheGreek
        </Button>
      </div>
    </div>
  );
}

export default Home;